<template>
  <div>
    <modal-confirm-generic
      ref="unSaveChangesModal"
      :description="$t('components/lims/modals/ModalFormCancel.text')"
      @onConfirm="onUnSaveChanges"
    />
    <component v-bind:is="activeEntityType" :entityTypeId="entityTypeId" :formMode="formMode"></component>
  </div>
</template>

<script>
import Clinic from './../Forms/Clinic/Clinic';
import Laboratory from './../Forms/Laboratory/Laboratory';
import ClinicLaboratory from './../Forms/ClinicLaboratory/ClinicLaboratory';
import { ENTITY_TYPES } from '@/core/constants';
import { UnSaveChangesMixins } from '@/core/mixins';

export default {
  mixins: [UnSaveChangesMixins],
  props: {
    entityTypeId: {
      require: false,
      default: null,
    },
    formMode: {
      type: Number,
      required: true,
    },
  },
  computed: {
    activeEntityType() {
      if (this.entityTypeId === ENTITY_TYPES.Clinic) {
        return Clinic;
      }
      if (this.entityTypeId === ENTITY_TYPES.Laboratory) {
        return Laboratory;
      }
      if (this.entityTypeId === ENTITY_TYPES.ClinicLaboratory) {
        return ClinicLaboratory;
      }
      return false;
    },
  },
  data: function () {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
